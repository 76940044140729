.container-order {
    max-width: 1300px;
    height: 80vh;
    background-color: #1c1c1c;

    &.order {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .cart-item-row {
            padding: 15px 25px;
        }

        .cart-item-data {
            padding: 0;

            width: 100%;
        }

        .cart-item-label {
            h3 {
                font-size: 32px;
                font-weight: 700;
                line-height: 38.73px;
                margin: 0;
            }
        }

        .price-orders {
            font-size: 18px;
            font-weight: 700;
            line-height: 21px;
            margin-top: 23px !important
        }

        .info-order {
            display: grid;
            grid-template-columns: 300px 1fr;
        }
    }
}