.orderInfo {
    .order-info-price {
        font-size: 24px;
        font-weight: 700;
        line-height: 21px;
    }

    .cart-item-label {
        display: flex;
        flex-direction: column;
        gap: 15px;

        h3 {

            margin: 0;
            line-height: 20px;
        }
    }
}