.telegram-wrapper {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 30px;

    .politic {
        width: 650px;
        display: block;
        text-align: center;
    }
}

.footer-auth {
    display: block;
    justify-content: center;
    width: 300px;
    margin: 0 auto;
    text-align: center;
    margin-top: 50px;
}