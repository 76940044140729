.row {
    display: flex;
    align-items: center;
    padding: 8px 0;
    border-bottom: 1px solid #333;
    font-size: 18px;
}

.row:last-child {
    border-bottom: none;
}

.label {
    width: 200px;
    color: #7D7D7D;
}

.value {
    text-align: right;
    color: #D9D9D9;
}