.content-wrapper {
    margin-top: 50px;
    display: flex;
    gap: 25px;
    flex-direction: column;

    .product-block {
        display: flex;
        gap: 110px;

        .slider {
            width: 639px;

            .image {
                width: 100%;
                height: 543px;
                object-fit: cover;
            }
        }
    }



    .product-content {
        display: flex;
        flex-direction: column;
        gap: 25px;
    }


    .count-product {
        color: #D9D9D9;
        margin-top: 25px;
        display: flex;
        flex-direction: column;
        gap: 5px;
    }

    .color-block {
        width: 53px;
        height: 53px;
        border-radius: 5px;
        background-color: red;
    }

    .size-block {
        width: 99px;
        height: 53px;
        border-radius: 5px;
        background-color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .size-letter {
            font-size: 24px;
            font-weight: 700;
            line-height: 29.05px;
        }

        .size-description {
            font-size: 10px;
            font-weight: 400;
            line-height: 12.1px;

        }
    }

    .small-white-button {
        padding: 3px 16px;
        border-radius: 12px;
        background-color: white;
        color: black;
        font-size: 18px;
        border: 2px solid white;
    }

    .activePr {
        border: 2px solid #1FB73A;
    }

    .description-block {
        max-width: 731px;

        p {
            font-size: 18px;
            font-weight: 400;
            line-height: 21.78px;
            margin: 0;
            padding: 0;
        }
    }

    .request-word {
        background-color: #494949;
        font-size: 18px;
        font-weight: 400;
        line-height: 21.78px;
        padding: 3px 16px;
        color: #D9D9D9;
        border-radius: 10px;
    }

    .cart-item-counter {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 5px;
        width: 125px;
        background-color: #222222;
        align-items: center;
        border-radius: 9px;
        height: 42px;
        color: white;
    }
}

.sub-title {
    color: white;
    font-size: 24px;
    font-weight: 700;
    line-height: 29px;
    margin: 0;
}

.slider {
    .slick-dots {

        display: flex !important;

        flex-direction: row;
        gap: 10px;
        bottom: -35px;

        li {
            width: 100% !important;

            &.slick-active {
                button {
                    &::before {
                        color: transparent !important;
                        background-color: #1FB73A;
                    }
                }
            }

            button {
                width: 100% !important;

                &::before {
                    color: transparent !important;
                    background-color: #A5A5A5;
                    width: 100% !important;
                    height: 10px !important;
                    border-radius: 10px;
                }
            }


        }
    }
}